import {
  lazy, Suspense, FC, ReactElement, useEffect, useRef
} from 'react'
import {
  Route, Navigate, createRoutesFromElements
} from 'react-router-dom'

import { useGetUserDetailsQuery } from '@store/services/user'
import useAnalytics from '@hooks/Analytics/useAnalytics'
import { useFlagsStatus } from '@unleash/proxy-client-react'
import { setStorageItem } from '@testavivadk/utilities'

import LoadingContainer from '@components/LoadingContainer'
import Message from '@frontend-components/components/Message'
import RootLayout from '../layouts/RootLayout'

import { applicationSpaRoutes } from '@testavivadk/common-tools/constants'
import { loginRedirectKey } from '@testavivadk/features'
import { isStartUnleashClient } from '@components/UnleashProvider/UnleashProvider'

const ProfileEdit = lazy(() => import(/* webpackChunkName: "ProfileEdit" */ './Profile/routes/Edit'))
const ProfileChangePassword = lazy(() => import(/* webpackChunkName: "ProfileChangePassword" */ './Profile/routes/ChangePassword'))
const ProfilePayments = lazy(() => import(/* webpackChunkName: "ProfilePayments" */ './Profile/routes/Payments'))
const ProfileBookings = lazy(() => import(/* webpackChunkName: "ProfileBookings" */ './Profile/routes/Bookings'))
const Basket = lazy(() => import(/* webpackChunkName: "Basket" */ './Basket'))
const CompletePurchase = lazy(() => import(/* webpackChunkName: "CompletePurchase" */ './CompletePurchase'))
const Receipt = lazy(() => import(/* webpackChunkName: "Receipt" */ './Receipt'))
const PageNotFound = lazy(() => import(/* webpackChunkName: "PageNotFound" */ './PageNotFound'))
const ConsentBorgerDK = lazy(() => import(/* webpackChunkName: "ConsentBorgerDK" */ './ConsentBorgerDK'))
const CreateDocument = lazy(() => import(/* webpackChunkName: "CreateDocument" */ './CreateDocument'))
const ResendActivationMail = lazy(() => import(/* webpackChunkName: "ResendActivationMail" */ './ResendActivationMail'))
const Login = lazy(() => import(/* webpackChunkName: "Login" */ './Login'))
const UserActivation = lazy(() => import(/* webpackChunkName: "UserActivation" */ './UserActivation'))
const Notifications = lazy(() => import(/* webpackChunkName: "Notifications" */ './Notifications'))
const MyOverview = lazy(() => import(/* webpackChunkName: "MyOverview" */ './MyOverview'))
const MyDocuments = lazy(() => import(/* webpackChunkName: "MyDocuments" */ './MyDocuments'))
const MitIdVerification = lazy(() => import(/* webpackChunkName: "MitIdVerification" */ './MitIdVerification'))
const Onboarding = lazy(() => import(/* webpackChunkName: "Onboarding" */ './Onboarding'))
const OnboardingCivilStatusView = lazy(() => import(/* webpackChunkName: "OnboardingCivilStatus" */'./Onboarding/routes/CivilStatus'))
const OnboardingValuesView = lazy(() => import(/* webpackChunkName: "OnboardingValues" */'./Onboarding/routes/Values'))
const OnboardingScoreView = lazy(() => import(/* webpackChunkName: "OnboardingScore" */'./Onboarding/routes/Score'))
const UploadId = lazy(() => import(/* webpackChunkName: "UploadId" */'./UploadId'))
const ApproveDocument = lazy(() => import(/* webpackChunkName: "ApproveDocument" */'./ApproveDocument'))
const Products = lazy(() => import(/* webpackChunkName: "Products" */'./Products'))
const Lawdocs = lazy(() => import(/* webpackChunkName: "Lawdocs" */ './Lawdocs'))

const FallBack: FC = () => <Message type="error">Der skete en uventet fejl. Prøv igen, eller kontakt vores support på 88 62 60 50</Message>

const ProtectedRoute: FC<{ children: ReactElement }> = ({ children }) => {
  const {
    data: userDetails,
    isLoading: isUserDetailsLoading
  } = useGetUserDetailsQuery()
  const identificationRef = useRef(false)
  const { identify } = useAnalytics()
  const { flagsReady } = useFlagsStatus()

  const isFlagsReady = !isStartUnleashClient ? true : flagsReady

  useEffect(() => {
    if (identificationRef.current) return

    if (userDetails?.id != null) {
      identificationRef.current = true
      identify(String(userDetails.id))
    }
  }, [userDetails])

  if (isUserDetailsLoading || !isFlagsReady) {
    return <LoadingContainer />
  }

  if (userDetails?.authenticated == null || !userDetails?.authenticated) {
    const path = `${window.location.pathname}${window.location.search}`

    if (!['/', '/login'].includes(path)) {
      setStorageItem(loginRedirectKey, path)
    }

    return <Navigate to={applicationSpaRoutes.login} />
  }

  return children
}

const LogoutOnlyRoute: FC<{ children: ReactElement }> = ({ children }) => {
  const {
    data: userDetails,
    isLoading: isUserDetailsLoading
  } = useGetUserDetailsQuery()

  if (isUserDetailsLoading) {
    return <LoadingContainer />
  }

  if (userDetails != null && userDetails.authenticated) {
    return <Navigate to={applicationSpaRoutes.myOverview} />
  }

  return children
}

export default createRoutesFromElements(
  <Route path="/" element={<RootLayout />} errorElement={<FallBack />}>
    <Route
      path="/"
      element={<ProtectedRoute><Suspense fallback={<LoadingContainer />}><PageNotFound /></Suspense></ProtectedRoute>}
    />
    {/* Protected routes */}
    <Route
      path={applicationSpaRoutes.profileEdit}
      element={<ProtectedRoute><Suspense fallback={<LoadingContainer />}><ProfileEdit /></Suspense></ProtectedRoute>}
    />
    <Route
      path={applicationSpaRoutes.profilePayments}
      element={<ProtectedRoute><Suspense fallback={<LoadingContainer />}><ProfilePayments /></Suspense></ProtectedRoute>}
    />
    <Route
      path={applicationSpaRoutes.profileBookings}
      element={<ProtectedRoute><Suspense fallback={<LoadingContainer />}><ProfileBookings /></Suspense></ProtectedRoute>}
    />
    <Route
      path={applicationSpaRoutes.profileChangePassword}
      element={<ProtectedRoute><Suspense fallback={<LoadingContainer />}><ProfileChangePassword /></Suspense></ProtectedRoute>}
    />
    <Route
      path={applicationSpaRoutes.basket}
      element={<ProtectedRoute><Suspense fallback={<LoadingContainer />}><Basket /></Suspense></ProtectedRoute>}
    />
    <Route
      path={applicationSpaRoutes.createDocument}
      element={<ProtectedRoute><Suspense fallback={<LoadingContainer />}><CreateDocument /></Suspense></ProtectedRoute>}
    />
    <Route
      path={applicationSpaRoutes.notifications}
      element={<ProtectedRoute><Suspense fallback={<LoadingContainer />}><Notifications /></Suspense></ProtectedRoute>}
    />
    <Route
      path={applicationSpaRoutes.myOverview}
      element={<ProtectedRoute><Suspense fallback={<LoadingContainer />}><MyOverview /></Suspense></ProtectedRoute>}
    />
    <Route
      path={applicationSpaRoutes.myDocuments}
      element={<ProtectedRoute><Suspense fallback={<LoadingContainer />}><MyDocuments /></Suspense></ProtectedRoute>}
    />
    <Route
      path={applicationSpaRoutes.mitIdVerification}
      element={<ProtectedRoute><Suspense fallback={<LoadingContainer />}><MitIdVerification /></Suspense></ProtectedRoute>}
    />
    <Route
      path={`${applicationSpaRoutes.approveDocument}/:id`}
      element={<ProtectedRoute><Suspense fallback={<LoadingContainer />}><ApproveDocument /></Suspense></ProtectedRoute>}
    />
    <Route
      path={applicationSpaRoutes.onboardingIndex}
      element={<ProtectedRoute><Suspense fallback={<LoadingContainer />}><Onboarding /></Suspense></ProtectedRoute>}
    >
      <Route
        path={applicationSpaRoutes.onboardingIndex}
        element={<Suspense fallback={<LoadingContainer />}><OnboardingCivilStatusView /></Suspense>}
      />
      <Route
        path={applicationSpaRoutes.onboardingValues}
        element={<Suspense fallback={<LoadingContainer />}><OnboardingValuesView /></Suspense>}
      />
      <Route
        path={applicationSpaRoutes.onboardingScore}
        element={<Suspense fallback={<LoadingContainer />}><OnboardingScoreView /></Suspense>}
      />
    </Route>
    <Route
      path={applicationSpaRoutes.products}
      element={<ProtectedRoute><Suspense fallback={<LoadingContainer />}><Products /></Suspense></ProtectedRoute>}
    />

    <Route
      path={applicationSpaRoutes.lawdocs}
      element={<Suspense fallback={<LoadingContainer />}><Lawdocs /></Suspense>}
    />
    {/* Logout only routes */}
    <Route
      path={applicationSpaRoutes.login}
      element={<LogoutOnlyRoute><Suspense fallback={<LoadingContainer />}><Login /></Suspense></LogoutOnlyRoute>}
    />
    <Route
      path={applicationSpaRoutes.userActivation}
      element={<LogoutOnlyRoute><Suspense fallback={<LoadingContainer />}><UserActivation /></Suspense></LogoutOnlyRoute>}
    />
    {/* Other routes */}
    <Route
      path={applicationSpaRoutes.completePurchase}
      element={<Suspense fallback={<LoadingContainer />}><CompletePurchase /></Suspense>}
    />
    <Route
      path={applicationSpaRoutes.receipt}
      element={<Suspense fallback={<LoadingContainer />}><Receipt /></Suspense>}
    />
    <Route
      path={applicationSpaRoutes.consentBorgerDK}
      element={<Suspense fallback={<LoadingContainer />}><ConsentBorgerDK /></Suspense>}
    />
    <Route
      path={applicationSpaRoutes.resendActivationMail}
      element={<Suspense fallback={<LoadingContainer />}><ResendActivationMail /></Suspense>}
    />
    <Route
      path={applicationSpaRoutes.uploadId}
      element={<Suspense fallback={<LoadingContainer />}><UploadId /></Suspense>}
    />
    <Route path="*" element={<Suspense fallback=""><PageNotFound /></Suspense>} />
  </Route>
)
