import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'

import { applicationUrl } from '@constants'
import { ILawdocs, ILawdocsSavePayload } from 'src/routes/Lawdocs/types/lawdocsDocuments.types'

export const LAWDOCS_API_REDUCER_KEY = 'lawdocsApi'
export const lawdocsApi = createApi({
  reducerPath: LAWDOCS_API_REDUCER_KEY,
  baseQuery: baseQueryWithAuth({
    baseUrl: applicationUrl
  }),
  tagTypes: ['LAWDOCS_DOCUMENT'],
  endpoints: (builder) => ({
    getLawdocsDocument: builder.query<ILawdocs, string>({
      query: (documentId) => ({
        url: `/lawdocs/load/${documentId}`,
        method: 'GET'
      }),
      providesTags: ['LAWDOCS_DOCUMENT']
    }),
    saveLawdocsDocument: builder.mutation<string, ILawdocsSavePayload>({
      query: ({
        type, purchase_type, document_id, document, history, complete
      }) => ({
        url: '/lawdocs/save',
        method: 'POST',
        type: 'application/json',
        body: {
          type,
          purchase_type,
          document_id,
          document: JSON.stringify(document),
          history: JSON.stringify(history),
          complete
        },
        responseHandler: (response) => response.text()
      }),
      transformResponse: (response: string) => response
    })
  })
})

export const {
  useGetLawdocsDocumentQuery,
  useLazyGetLawdocsDocumentQuery,
  useSaveLawdocsDocumentMutation
} = lawdocsApi
