import {
  useRef, FC, useState
} from 'react'

import { useTranslation } from 'react-i18next'
import useAnalytics from '@hooks/Analytics/useAnalytics'
import { useGetAllNotificationsQuery, usePostMarkNotificationAsReadMutation } from '@store/services/notifications'

import {
  Button, Link as MuiLink, MenuItem, Menu as MuiMenu,
  Box,
  MenuList,
  Alert
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { NotificationsNone as NotificationsIcon } from '@mui/icons-material'
import { applicationSpaRoutes } from '@testavivadk/common-tools/constants'

import { INotification } from '@types'
import { themeColors } from '@testavivadk/css-tools/css/themeColors'
import { red } from '@mui/material/colors'

const Notifications: FC = () => {
  const ref = useRef<HTMLButtonElement>(null)
  const { t } = useTranslation()
  const { track } = useAnalytics()

  const { data: notificationsData = [] } = useGetAllNotificationsQuery(null)
  const [readNotification] = usePostMarkNotificationAsReadMutation()

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
  const [isErrorAlertVisible, setErrorAlertVisible] = useState(false)
  const navigate = useNavigate()

  const handleClick = (id : INotification['id']) => {
    // components has some accessibility issues, so data-analytics attribute doesn't work.
    track('global.link.headerReadNotification')
    if (notificationsData?.find((notification) => notification.id === id)?.is_read) {
      setIsNotificationsOpen(false)
      navigate(applicationSpaRoutes.notifications)
      return
    }

    try {
      readNotification(id)
    } catch {
      setErrorAlertVisible(true)
      return
    }

    setIsNotificationsOpen(false)
    navigate(applicationSpaRoutes.notifications)
  }

  return (
    <>
      <Button
        sx={{ height: '100%' }}
        data-cy="notification-button"
        onClick={() => setIsNotificationsOpen((prevOpen) => !prevOpen)}
        ref={ref}
        title={t('appHeader.notifications.showMyNotifications') as string}
        data-analytics="header.button.toggleNotificationPopup"
      >
        {notificationsData.some((notification) => !notification.is_read) && (
          <Box
            component="span"
            sx={{
              bgcolor: red[900],
              position: 'absolute',
              top: '0.8rem',
              right: '1.2rem',
              width: '0.6rem',
              height: '0.6rem',
              borderRadius: '50%'
            }}
          />
        )}
        <NotificationsIcon
          sx={{
            fontSize: '1.5rem',
            pointerEvents: 'none'
          }}
        />
      </Button>
      <MuiMenu
        open={isNotificationsOpen}
        anchorEl={ref.current}
        onClose={() => setIsNotificationsOpen((prevOpen) => !prevOpen)}
        disableScrollLock
      >
        <MenuList
          sx={{
            pb: 0,
            width: '20rem'
          }}
        >
          {notificationsData.length > 0 && (
            [...notificationsData].sort((a, b) => ((a.is_read ? 1 : 0) > (b.is_read ? 1 : 0) ? 1 : -1)).slice(0, 3).map((notification) => (
              <MenuItem
                sx={[
                  !notification.is_read && {
                    bgcolor: themeColors.lighterTeal
                  }
                ]}
                onClick={() => handleClick(notification.id)}
                key={notification.id}
              >
                <Box
                  sx={{
                    textWrap: 'wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '4',
                    WebkitBoxOrient: 'vertical'
                  }}
                  dangerouslySetInnerHTML={{ __html: notification.content }}
                />
              </MenuItem>
            ))
          )}
          {isErrorAlertVisible && (
            <Alert severity="error">
              {t('global.errors.genericError')}
            </Alert>
          )}
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '0.5rem'
          }}
          >
            {notificationsData.length > 0 ? (
              <MuiLink
                component={Link}
                to={applicationSpaRoutes.notifications}
                onClick={() => setIsNotificationsOpen(false)}
                data-analytics="header.link.seeAllNotification"
              >
                {t('appHeader.notifications.seeAll')}
              </MuiLink>
            ) : (
              <MuiLink
                component={Link}
                to={applicationSpaRoutes.notifications}
                onClick={() => setIsNotificationsOpen(false)}
                data-analytics="header.link.noNotifications"
              >
                {t('appHeader.notifications.noNotifications')}
              </MuiLink>
            )}
          </Box>
        </MenuList>
      </MuiMenu>
    </>
  )
}

export default Notifications
