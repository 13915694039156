import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGetDocumentPaidTokensQuery, useConsumeProductTokenMutation } from '@store/services/ecommerce'
import { useGetUserDetailsQuery } from '@store/services/user'

import Cookies from 'js-cookie'

import { Dialog } from '@mui/material'
import UnlockLoadingContent from './components/UnlockLoadingContent'
import HasUnlockedContent from './components/HasUnlockedContent/HasUnlockedContent'
import { useFlag } from '@unleash/proxy-client-react'

const UnlockDocumentAfterPaymentModal = () => {
  const isModalVisible = useFlag('UnlockDocumentAfterPaymentModal')
  const isEcommerceUserCartEnabled = useFlag('EcommerceUserCart')

  const { data: userDetailsData } = useGetUserDetailsQuery()
  const {
    data: documentPaidTokenData = [],
    isLoading: isDocumentPaidTokenLoading,
    isError: isDocumentPaidTokenError
  } = useGetDocumentPaidTokensQuery({ userId: Number(userDetailsData?.id), consumed: false }, { skip: userDetailsData?.id == null || !isEcommerceUserCartEnabled })

  const [consumeToken, { isLoading: isConsumeTokenLoading, isError: isConsumeTokenError }] = useConsumeProductTokenMutation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [isUserConsumingAToken, setIsUserConsumingAToken] = useState(false)

  const isShopifyReturn = searchParams.get('shopifyReturn') === 'true'

  const documentTokenCookie = Cookies.get('documentToken')

  useEffect(() => {
    if (isShopifyReturn && documentTokenCookie != null) {
      setIsUserConsumingAToken(true)
      const { documentId, purchaseType, productType } = JSON.parse(decodeURIComponent(documentTokenCookie))
      const hasToken = (documentPaidTokenData.filter((token) => token.product_type === productType).length) > 0
      if (hasToken) {
        consumeToken({
          userId: Number(userDetailsData?.id),
          consumedBy: documentId,
          productType,
          purchaseType
        })
        Cookies.remove('documentToken', { domain: '.testaviva.dk' })
      }
    }
  }, [documentPaidTokenData])

  if (!isShopifyReturn || !isModalVisible || !isUserConsumingAToken) return null

  return (
    <Dialog open onClose={() => setSearchParams('')}>
      {isConsumeTokenLoading || isDocumentPaidTokenLoading ? (
        <UnlockLoadingContent />
      ) : (
        <HasUnlockedContent
          consumeError={isConsumeTokenError}
          loadTokensError={isDocumentPaidTokenError}
        />
      )}
    </Dialog>
  )
}

export default UnlockDocumentAfterPaymentModal
