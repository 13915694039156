import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TLawdocsEventEmit } from 'src/routes/Lawdocs/types/lawdocsEvents.types'

export type Observer = {
  id: string
  function: (event: TLawdocsEventEmit, parameters: any) => void
}
interface LawdocsState {
  isChannelReady: boolean;
  messageQueue: any[];
  observers: Observer[];
  alertMessage: {
    isShowAlert: boolean;
    message?: string;
    severity?: 'info' | 'error' | 'warning' | 'success';
  };
}

const initialState: LawdocsState = {
  isChannelReady: false,
  messageQueue: [],
  observers: [],
  alertMessage: {
    isShowAlert: false,
    message: '',
    severity: 'info'
  }
}

const lawdocsSlice = createSlice({
  name: 'lawdocs',
  initialState,
  reducers: {
    setIsChannelReady(state, action: PayloadAction<boolean>) {
      state.isChannelReady = action.payload
    },
    setMessageQueue(state, action: PayloadAction<any[]>) {
      state.messageQueue = action.payload
    },
    addMessageToQueue(state, action: PayloadAction<any>) {
      state.messageQueue.push(action.payload)
    },
    clearMessageQueue(state) {
      state.messageQueue = []
    },
    setObservers(state, action: PayloadAction<Observer[]>) {
      state.observers = action.payload
    },
    addObserver(state, action: PayloadAction<Observer>) {
      state.observers.push(action.payload)
    },
    removeObserver(state, action: PayloadAction<string>) {
      state.observers = state.observers.filter((observer) => observer.id !== action.payload)
    },
    clearObservers(state) {
      state.observers = []
    },
    setAlertMessage(state, action: PayloadAction<{ isShowAlert: boolean, message?: string, severity?: 'info' | 'error' | 'warning' | 'success' }>) {
      state.alertMessage = action.payload
    }
  }
})

export const {
  setIsChannelReady,
  setMessageQueue,
  addMessageToQueue,
  clearMessageQueue,
  setObservers,
  addObserver,
  removeObserver,
  clearObservers,
  setAlertMessage
} = lawdocsSlice.actions

export default lawdocsSlice.reducer
