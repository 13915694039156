import { FC, useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import useAnalytics from '@hooks/Analytics/useAnalytics'

// Following components is responsible for certain things:

// Tracks all page visits by subscribing to router location changes.
// Monitors clicks on all elements with a `data-analytics` attribute for analytics tracking.
// If an element lacks the `data-analytics` attribute, it listens for clicks on <a> or <button> elements, attempts to retrieve their text content, and sends it to analytics (if available).
// For custom event tracking, use the `useAnalytics().track('context')` hook.

const Analytics: FC = () => {
  const { page, track } = useAnalytics()
  const location = useLocation()

  useEffect(() => {
    page(location.pathname)
  }, [location])

  useEffect(() => {
    const controller = new AbortController()

    window.addEventListener('click', (event) => {
      const target = event.target as HTMLElement
      const segmentKeyDataAttribute = target.getAttribute('data-analytics')

      const trackElement = (element: HTMLElement) => {
        const elementText = element.textContent || element.innerText
        if (elementText != null && elementText.length > 0) {
          track(elementText)
        }
      }

      if (segmentKeyDataAttribute != null) {
        track(segmentKeyDataAttribute)
        return
      }

      if (['A', 'BUTTON'].includes(target.tagName)) {
        trackElement(target)
        return
      }

      const parentElement = target?.parentElement as HTMLElement
      if (parentElement != null && ['A', 'BUTTON'].includes(parentElement.tagName)) {
        trackElement(parentElement)
      }
    }, { signal: controller.signal })

    return () => {
      controller.abort()
    }
  }, [])

  return null
}

export default Analytics
