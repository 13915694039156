import { supportUrl } from '@constants'
import { applicationSpaRoutes } from '@testavivadk/common-tools/constants'

export const menuLinks = [
  {
    name: 'myOverview',
    url: applicationSpaRoutes.myOverview
  },
  {
    name: 'myDocuments',
    url: applicationSpaRoutes.myDocuments
  },
  {
    name: 'createDocument',
    url: applicationSpaRoutes.createDocument
  },
  {
    name: 'support',
    url: supportUrl
  }
]

// TODO: when the flag "EcommerceUserCart" is removed, this shall become the new menuLinks instead of having 2

export const cartFlaggedMenuLinks = [
  {
    name: 'myOverview',
    url: applicationSpaRoutes.myOverview
  },
  {
    name: 'myDocuments',
    url: applicationSpaRoutes.myDocuments
  },
  {
    name: 'products',
    url: '/produkter'
  },
  {
    name: 'support',
    url: supportUrl
  }
]
